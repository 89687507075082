import React, { useState } from 'react'
import {
  Alert,
  Button,
  Center,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import {
  Logo,
  PasswordInput,
  TermsAndPrivacyText,
  TextDivider
} from '.'
import { FaGooglePlusSquare, FaLock } from 'react-icons/fa'
import { useLocation } from 'wouter'
import { useError } from '../hooks'

interface Props {
  loginLink?: string,
  redirectLink?: string,
  onGoogleLogin: () => {},
  onSignup: (email: string, password: string) => {},
  onLoginClick?: () => void,
}

export const SignupPane = ({
  loginLink = "/login",
  redirectLink = "/",
  onGoogleLogin,
  onSignup,
  onLoginClick,
}: Props) => {
  // eslint-disable-next-line
  const [_, setLocation] = useLocation()
  const [email, setEmail] = React.useState("")
  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
  const [password, setPassword] = React.useState("")
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)

  const loadingText = "In progress..."
  const [emailInProgress, setEmailInProgress] = useState(false)
  const [googleInProgress, setGoogleInProgress] = useState(false)

  const googleError = useError()
  const emailError = useError()

  const loginWithGoogle = async () => {
    try {
      setGoogleInProgress(true)
      googleError.clear()
      emailError.clear()
      await onGoogleLogin()
    } catch (error) {
      googleError.setError(error.message)
    } finally {
      setGoogleInProgress(false)
    }
  }

  const signupWithEmail = async () => {
    try {
      setEmailInProgress(true)
      googleError.clear()
      emailError.clear()
      await onSignup(email, password)
      setLocation(redirectLink)
    } catch (error) {
      emailError.setError(error.message)
    } finally {
      setEmailInProgress(false)
    }
  }

  return (
    <VStack
      spacing={5}
      mx={{ base: 1, md: 16 }}
      align="stretch"
    >
      <Spacer />
      <Spacer />
      <Center>
        <Logo />
      </Center>
      <Spacer />
      <Center>
        <Heading as="h4" size="md" color="blue.600">
          You're welcome.
        </Heading>
      </Center>
      {
        googleError.hasError && <Alert status="error" variant="left-accent">
          { googleError.errorMessage }
        </Alert>
      }
      <Button
        colorScheme="blue"
        variant="outline"
        size="lg"
        onClick={loginWithGoogle}
        leftIcon={<FaGooglePlusSquare />}
        isLoading={googleInProgress}
        loadingText={loadingText}
      >
        Continue with Google
      </Button>
      <TextDivider text="or" />
      <Text fontSize="md" fontWeight="500">
        Create your account
      </Text>
      <Center>
        <Text fontSize="md">
          Already signed up?&nbsp;
          {
            onLoginClick
              ? <Button colorScheme="blue" variant="link" onClick={onLoginClick} >
                  Login
                </Button>
              : <Link color="blue.600" href={loginLink} fontWeight="bold">
                  Login
                </Link>
          }
        </Text>
      </Center>
      {
        emailError.hasError && <Alert status="error" variant="left-accent">
          { emailError.errorMessage }
        </Alert>
      }
      <FormControl id="email">
        <FormLabel display="none">Email address</FormLabel>
        <Input
          type="email"
          placeholder="Your email address"
          size="lg"
          value={email}
          onChange={onEmailChange} />
      </FormControl>
      <FormControl id="password">
        <FormLabel display="none">Password</FormLabel>
        <PasswordInput
          placeholder="Create a password"
          size="lg"
          value={password}
          onChange={onPasswordChange} />
      </FormControl>
      <Button
        colorScheme="green"
        size="lg"
        onClick={signupWithEmail}
        leftIcon={<FaLock />}
        isLoading={emailInProgress}
        loadingText={loadingText}
      >
        Sign up
      </Button>
      <Divider />
      <Center>
        <TermsAndPrivacyText />
      </Center>
      <Spacer />
    </VStack>
  )
}

import React, { useState } from 'react'
import TimeAgo from 'react-timeago'
import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  Text,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { WatchlistModel } from '../../types'
import { ConfirmDialog } from '../../components'
import { FaCheckCircle, FaTimesCircle, FaTrashAlt } from 'react-icons/fa'

type Props = {
  watchlist: WatchlistModel,
  onActivate: () => Promise<void>,
  onDeactivate: () => Promise<void>,
  onDelete: () => Promise<void>,
}

export const WatchlistCard = ({
  watchlist,
  onActivate,
  onDeactivate,
  onDelete,
}: Props) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const onActivateClicked = async () => {
    try {
      await onActivate()
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  const onDeactivateClicked = async () => {
    try {
      await onDeactivate()
    } catch (error) {
      console.log(error)
    } finally {
    }
  }

  const onDeleteClicked = () => {
    setShowConfirmDialog(true)
  }

  const onDeleteConfirmed = async () => {
    setShowConfirmDialog(false)
    await onDelete()
  }

  return (
    <Box
      shadow="sm"
      width="full"
      borderWidth="1px"
      borderRadius="md"
      overflow="hidden"
      borderLeftWidth={5}
      borderLeftColor={watchlist.active ? "green.300" : "yellow.300"}
    >
      <Flex width="full">
        <Stack flex="1" px={2} py={1} spacing={1} width="full">
          <Heading fontSize="md" isTruncated>
            {watchlist.name}
          </Heading>
          <Link fontSize="lg" href={watchlist.url} isExternal>
            <Text isTruncated>
              {watchlist.url}
            </Text>
          </Link>
          <Flex>
            <Stat flex="1">
              <StatLabel>Queries</StatLabel>
              <StatNumber fontSize="md">{watchlist.queries}</StatNumber>
            </Stat>
            <Stat flex="1">
              <StatLabel>Alerts</StatLabel>
              <StatNumber fontSize="md">{watchlist.alerts}</StatNumber>
            </Stat>
            <Stat flex={{ base: "2", md: "1" }}>
              <StatLabel>Last checked</StatLabel>
              <StatNumber fontSize="md">
                {
                  watchlist.lastChecked
                    ? <TimeAgo live date={watchlist.lastChecked} />
                    : "..."
                }
              </StatNumber>
            </Stat>
          </Flex>
          <Divider />
          <HStack spacing={5}>
            <Tag>{watchlist.pricingPlan}</Tag>
            <Spacer />
            <Menu placement="bottom-start">
              <MenuButton
                px={4}
                py={2}
                transition="all 0.2s"
                borderRadius="md"
                borderWidth="1px"
                _hover={{ bg: "gray.100" }}
                _focus={{ outline: 0, boxShadow: "outline" }}
              >
                Actions <ChevronDownIcon />
              </MenuButton>
              <MenuList>
                <MenuGroup title="Status">
                  <MenuItem onClick={onActivateClicked} isDisabled={watchlist.active}>
                    <FaCheckCircle />&nbsp;&nbsp;Activate
                  </MenuItem>
                  <MenuItem onClick={onDeactivateClicked} isDisabled={!watchlist.active}>
                    <FaTimesCircle />&nbsp;&nbsp;Deactivate
                  </MenuItem>
                </MenuGroup>
                <MenuGroup title="Delete">
                  <MenuItem onClick={onDeleteClicked}>
                    <FaTrashAlt />&nbsp;&nbsp;Delete Watchlist
                  </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          </HStack>
        </Stack>
      </Flex>
      <ConfirmDialog
        headerText="Confirm Delete"
        confirmButtonText="Delete"
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false) }
        onConfirm={onDeleteConfirmed}
      />
    </Box>
  )
}

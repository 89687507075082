import React from 'react'
import { Route, Switch } from 'wouter'
import Index from './watchlists/Index'

export default () => {
  return (
    <Switch>
      <Route path="/" component={Index} />
      <Route path="/watchlists" component={Index} />
      <Route>404, Not Found!</Route>
    </Switch>
  )
}

import React, { useEffect, useState } from 'react'
import { Box, useDisclosure, useToast, VStack } from '@chakra-ui/react'
import { Fab } from 'react-tiny-fab'
import { Main, DefaultNavbar } from '../../components'
import { WatchlistCard } from './WatchlistCard'
import { db, functions } from '../../../firebase'
import { useSession } from '../../auth'
import { ModalForm } from './ModalForm'
import 'react-tiny-fab/dist/styles.css'
import { AddIcon } from '@chakra-ui/icons'
import { WatchlistModel, WatchlistRequestModel } from '../../types'

const DocToWatchlist = (id: string, docData: any): WatchlistModel => {
  return {
    id: id,
    name: docData.name,
    url: docData.url,
    active: docData.active,
    queries: docData.queries,
    alerts: docData.alerts,
    lastChecked: docData.lastChecked?.toDate(),
    pricingPlan: docData.pricingPlan
  }
}

export default function () {
  const toast = useToast()
  const user = useSession();
  const userId = user?.uid
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [watchlists, setWatchlists] = useState<WatchlistModel[]>([])

  useEffect(() => {
    const unsubscribe = db.collection(`users/${userId}/watchlists`).onSnapshot(
      (snapshot) => {
        const list: WatchlistModel[] = []
        snapshot.forEach((doc) => list.push(DocToWatchlist(doc.id, doc.data())))
        setWatchlists(list);
      },
      (error) => console.error(error))

    return () => { unsubscribe() }
  }, [userId])

  const onCreateWatchlist = async (values: WatchlistRequestModel): Promise<void> => {
    var addWatclist = functions.httpsCallable('callableCreateWatchlistFn');
    
    try {
      const result = await addWatclist(values);
      
      if (result.data.success) {
        toast({
          title: "Watchlist Created",
          description: "Your watchlist has been created",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
        onClose()
        return Promise.resolve()
      } else {
        toast({
          title: result.data.error,
          description: "Please complete all information required",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
        return Promise.reject()
      }
    } catch (error) {
      toast({
        title: "Error encountered",
        description: "This might not be your fault but do get in touch with us",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
      console.log(error);
      return Promise.reject()
    }
  }

  const onAction = async (
    action: string,
    id: string
  ): Promise<boolean> => {
    const data = { id }
    var idAction = functions.httpsCallable(action);
    
    try {
      const result = await idAction(data);
      
      return Promise.resolve(!!result.data.success)
    } catch (error) {
      return Promise.reject(false)
    }
  }

  const onActivate = async (id: string): Promise<void> => {
    onAction('callableActivateWatchlistFn', id)
  }

  const onDeactivate = async (id: string): Promise<void> => {
    onAction('callableDeactivateWatchlistFn', id)
  }

  const onDelete = async (id: string): Promise<void> => {
    onAction('callableDeleteWatchlistFn', id)
  }

  return (
    <Main>
      <DefaultNavbar />
      <Box mt={100} mb={150} maxWidth="800px" marginX="auto">
        <VStack>
          {
            watchlists.map(w => (
              <WatchlistCard
                key={w.id}
                watchlist={w}
                onActivate={ () => onActivate(w.id) }
                onDeactivate={ () => onDeactivate(w.id) }
                onDelete={ () => onDelete(w.id) }
              />
            ))
          }
        </VStack>
      </Box>
      <ModalForm
        isOpen={isOpen}
        onCreateWatchlist={onCreateWatchlist}
        onCancel={onClose}
        onClose={onClose}
      />
      <Fab
        mainButtonStyles={{ backgroundColor: '#44bb87' }}
        icon={<AddIcon />}
        event="click"
        onClick={onOpen}
        text="Add Watchlist"
      />
    </Main>
  )
}

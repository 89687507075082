import React, { ReactElement, useState } from 'react'
import { BrandingPane, ColorModeSwitcher, LoginPane, Main, SignupPane } from '../../components'
import { createUserWithEmail, loginWithGoogle, loginWithEmail } from '../../auth'
import { Box } from '@chakra-ui/react'
import { useWindowDimensionsInPx } from '../../hooks'

export default function (): ReactElement {
  const [isLogin, setIsLogin] = useState(true)
  const { height } = useWindowDimensionsInPx();

  const login = (
  <LoginPane
    onGoogleLogin={loginWithGoogle}
    onEmailLogin={loginWithEmail}
    onSignupClick={ () => setIsLogin(!isLogin) }
  />
  )

  const signup = (
    <SignupPane
      onGoogleLogin={loginWithGoogle}
      onSignup={createUserWithEmail}
      onLoginClick={ () => setIsLogin(!isLogin) }
    />
  )

  return (
    <>
      <Main px={0} display={{ md: "flex" }} height={height}>
        <Box position="absolute" right="8px" top="8px">
          <ColorModeSwitcher />
        </Box>
        <Box
          display={{
            base: "none",
            md: "block"
          }}
          width="full"
        >
          <BrandingPane />
        </Box>
        <Box
          px={4}
          width={{
            base: "100%",
            md: "48em"
          }}
        >
          { isLogin ? login : signup }
        </Box>
      </Main>
    </>
  )
}

import React from 'react';
import { Route, Redirect, useRoute, Switch } from 'wouter';
import { useAuthState } from 'react-firebase-hooks/auth';
import { userContext } from './contexts';
import AuthPage from './routes/auth/Index';
import Login from './routes/login/Index';
import Signup from './routes/signup/Index';
import DefaultLayout from './routes/DefaultLayout';
import { auth as firebaseAuth } from './../firebase';
import { ChakraProvider } from '@chakra-ui/react';
import { PrivacyPolicy } from './routes/legal/PrivacyPolicy';
import { TermsAndConditions } from './routes/legal/TermsAndConditions';

import { customTheme } from './themes';
import { LoadingFull } from './components';

interface PrivateRouteProps {
  component: any;
  path: string;
}

const PrivateRoute = ({
  component: Component,
  path,
  ...other
}: PrivateRouteProps) => {
  const user = firebaseAuth.currentUser;
  const [, params] = useRoute(path);

  if (!user && params?.rest) {
    return <Redirect to="login" />;
  }

  if (!user) {
    return null;
  }

  return <Component />;
};

function App() {
  const [user, initialising, error] = useAuthState(firebaseAuth);

  if (initialising) {
    return (
      <ChakraProvider theme={customTheme}>
        <LoadingFull />
      </ChakraProvider>
    );
  }
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }

  return (
    <userContext.Provider
      value={{
        user: user,
        initialising
      }}
    >
      <ChakraProvider theme={customTheme}>
        <div className="App">
          {!user && <Route path="/" component={AuthPage} />}
          <Switch>
            <Route path="/auth" component={AuthPage} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/legal/privacy-policy" component={PrivacyPolicy} />
            <Route path="/legal/terms-and-conditions" component={TermsAndConditions} />
            <PrivateRoute path="/:rest*" component={DefaultLayout} />
          </Switch>
        </div>
      </ChakraProvider>
    </userContext.Provider>
  );
}

export default App;

import React from 'react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { SimpleForm, SimpleFormFieldProps } from '../../components/SimpleForm'
import { WatchlistRequestModel } from '../../types'

interface Props {
  isOpen: boolean,
  onClose: () => void,
  onCreateWatchlist: (values: WatchlistRequestModel) => Promise<void>,
  onCancel: () => void,
}

export const ModalForm = ({
  isOpen,
  onClose,
  onCreateWatchlist,
  onCancel
}: Props) => {
  const fields: SimpleFormFieldProps[] = [
    {
      name: "name",
      label: "Name",
      type: "text",
      placeholder: "Marketing Jobs",
      helperText: "Friendly name",
      isRequired: true,
    },
    {
      name: "siteUrl",
      label: "Listing URL",
      type: "textarea",
      placeholder: "https://abcjob.com?category=marketing",
      isRequired: true,
    },
  ]

  const initialValues: WatchlistRequestModel = {
    name: "",
    siteUrl: "",
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Create A Watchlist</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleForm<WatchlistRequestModel>
              fields={fields}
              initialValues={initialValues}
              onSubmit={onCreateWatchlist}
              onCancel={onCancel}
              submitText="Create Watchlist"
              submittingText="Setting up your watchlist"
            />
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}

import React from 'react'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Select,
  Spacer,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import {
  Form,
  Formik,
  FormikProps,
  Field,
  FieldAttributes,
} from 'formik'

interface SimpleFormProps<TValueProps> {
  fields: SimpleFormFieldProps[],
  initialValues: TValueProps,
  submitText?: string,
  cancelText?: string,
  submittingText?: string,
  onSubmit: (values: TValueProps) => Promise<void>,
  onCancel: () => void,
}

interface SimpleFormInputFieldProps {
  name: string,
  label: string,
  type: "text" | "number" | "email" | "phone" | "textarea",
  placeholder?: string,
  helperText?: string,
  isRequired?: boolean,
}

interface SimpleFormKVPair {
  key: string,
  value: string,
}

interface SimpleFormSelectFieldProps {
  name: string,
  label: string,
  data: SimpleFormKVPair[],
  placeholder?: string,
  helperText?: string,
  isRequired?: boolean,
}

type SimpleFormFieldProps = SimpleFormInputFieldProps | SimpleFormSelectFieldProps

const SimpleFormInputField = ({
  name,
  label,
  type,
  placeholder = "",
  helperText,
  isRequired = false,
}: SimpleFormInputFieldProps) => {
  const validate = (value: string): string | null => {
    if (isRequired && !value) {
      return "Required"
    }

    // eslint-disable-next-line
    const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
    if (type === "email" && !filter.test(value)) {
      return "Email is not valid"
    }

    return null
  }

  return (
    <Field name={name} validate={validate}>
      {({ field, form }: FieldAttributes<any>) => (
        <FormControl isRequired={isRequired} isInvalid={form.errors[name] && form.touched[name]}>
          <FormLabel htmlFor={name}>{label}</FormLabel>
          {
            type === "textarea"
              ? <Textarea {...field} id={name} placeholder={placeholder} />
              : <Input {...field} id={name} type={type} placeholder={placeholder} />
          }
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          { helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  )
}

const SimpleFormSelectField = ({
  name,
  label,
  data,
  placeholder = "",
  helperText,
  isRequired = false,
}: SimpleFormSelectFieldProps) => {
  const validate = (value: string): string | null => {
    if (isRequired && !value) {
      return "Required"
    }

    return null
  }

  return (
    <Field name={name} validate={validate}>
      {({ field, form }: FieldAttributes<any>) => (
        <FormControl isRequired={isRequired} isInvalid={form.errors[name] && form.touched[name]}>
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <Select {...field} id={name} placeholder={placeholder}>
            <option key="default_unused" value=""></option>
            {
              data.map(row => {
                return (
                  <option key={row.key} value={row.key}>{row.value}</option>
                )
              })
            }
          </Select>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
          { helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  )
}

const SimpleForm = function <TValueProps>({
  fields,
  initialValues,
  submitText = "Submit",
  cancelText = "Cancel",
  submittingText = "",
  onSubmit,
  onCancel,
}: SimpleFormProps<TValueProps>) {
  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting(true)
            await onSubmit(values)
          } catch (error) {
            console.log(error)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {(props: FormikProps<TValueProps>) => (
          <Form onSubmit={props.handleSubmit}>
            <VStack spacing={4}>
              {
                fields.map(field => {
                  if ((field as SimpleFormInputFieldProps).type) {
                    return (
                      <SimpleFormInputField
                        {...(field as SimpleFormInputFieldProps)}
                        key={field.name}
                      />
                    )
                  } else if ((field as SimpleFormSelectFieldProps).data) {
                    return (
                      <SimpleFormSelectField
                        {...(field as SimpleFormSelectFieldProps)}
                        key={field.name}
                      />
                    )
                  }

                  return null
                })
              }
              <Divider />
              <HStack my={4}>
                <Button
                  colorScheme="teal"
                  isLoading={props.isSubmitting}
                  loadingText={submittingText}
                  type="submit"
                >
                  {submitText}
                </Button>
                <Spacer width={1} />
                {!props.isSubmitting && <Button
                  colorScheme="red"
                  variant="outline"
                  type="button"
                  onClick={onCancel}
                >
                  {cancelText}
                </Button>}
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export type {
  SimpleFormProps,
  SimpleFormInputFieldProps,
  SimpleFormSelectFieldProps,
  SimpleFormFieldProps,
}

export {
  SimpleForm
}

import React from 'react';
import {
  Button,
  chakra,
  Flex,
  Spacer,
} from '@chakra-ui/react'
import {
  ColorModeSwitcher,
  HeaderNavLink,
  Logo,
  Navbar
} from '.';

interface Props {
  loginLink?: string,
  signupLink?: string,
}

export const NoAuthNavbar = ({
  loginLink = "/login",
  signupLink = "/signup",
}: Props) => {
  return (
    <Navbar>
      <Flex align="center">
        <HeaderNavLink href="/">
          <chakra.a ml={-3} display="block" aria-label="InsureWise, Back to homepage">
            <Logo height={30} />
          </chakra.a>
        </HeaderNavLink>
      </Flex>

      <Flex maxW="720px" align="right" color="gray.400">
        <ColorModeSwitcher />
        <Spacer width={1} />
        <Button as="a" color="blue.300" variant="outline" href={loginLink} fontWeight="bold">
          Login
        </Button>
        <Spacer width={1} />
        <Button as="a" color="blue.300" href={signupLink} fontWeight="bold">
          Sign up
        </Button>
      </Flex>
    </Navbar>
  )
}
